import { Box, Button, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useMobile from "../hooks/useMobile";
import { IFormType } from "../exports/interfaces";
import axios from "axios";
import { headers } from "../exports/exports";
import { dynamicFailed, failed, success } from "../hooks/useToast";
import { LoadingContext, MapLinkContext } from "../pages/CompanyProfilePage";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const mobile = useMobile();

  const { mapLink, setMapLink } = useContext(MapLinkContext);

  const { setLoading } = useContext(LoadingContext);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const getMapLink = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/api/contact-maps?pagination[pageSize]=1&sort=createdAt%3Adesc`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setMapLink(res.data.data[0].attributes.Map_Link);
        setLoading(false);
      })
      .catch(() => {
        dynamicFailed("Server Error");
        setLoading(false);
      });
  };

  useEffect(() => {
    mapLink === "" && getMapLink();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLink]);

  const formItems = [
    { name: "Full Name", key: "Name", type: "string" },
    { name: "Email", key: "Email", type: "string" },
    { name: "Contact Number", key: "Contact_Number", type: "number" },
    { name: "Message", key: "Message", type: "string" },
  ];

  const initialFormData: IFormType = {
    Name: null,
    Email: null,
    Contact_Number: null,
    Message: null,
  };

  const [formData, setFormData] = useState<IFormType>(initialFormData);

  const [captchaToken, setCaptchaToken] = useState<boolean>(false);

  const sendMessage = () => {
    if (!captchaToken) {
      failed();
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_strapiUrl}/api/messages`,
        { data: formData },
        { headers: headers }
      )
      .then(() => {
        success();
        setFormData(initialFormData);

        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }

        setCaptchaToken(false);
      })
      .catch(() => failed());
  };

  const recaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaToken(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={mobile ? "unset" : "40px"}
        gap="10px"
        height={mobile ? "100dvh" : "100%"}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={mobile ? "0px" : "40px"}
          flexDirection={mobile ? "column" : "row"}
          height="100%"
          width="100%"
        >
          <Box
            flex={mobile ? "unset" : "1"}
            order={mobile ? 2 : 1}
            sx={{ width: "100%", height: mobile ? "480px" : "375px" }}
          >
            {formItems.map((item) => (
              <TextField
                label={item.name}
                type={item.type}
                key={item.key}
                required
                value={formData[item.key as keyof IFormType] || ""}
                size="small"
                fullWidth
                variant="standard"
                multiline={item.key === "Message"}
                rows={item.key === "Message" ? 4 : 1}
                onChange={(e) => {
                  const value = e.target.value;
                  setFormData((prevForm: IFormType) => ({
                    ...prevForm,
                    [item.key]: value,
                  }));
                }}
                sx={{
                  my: "3px",
                  "& label.Mui-focused": {
                    color: "blue",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#384A62",
                  },
                  "& .MuiInputBase-root.MuiInput-root": {
                    color: "black",
                    fontWeight: "normal",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root": {
                    color: "black",
                    fontWeight: "normal",
                  },
                  "& .MuiInputBase-root.MuiInput-root:before": {
                    borderBottom: `1px solid gray`,
                  },
                  "& .MuiFormLabel-asterisk": {
                    color: "red",
                    ml: "-2px",
                  },
                }}
              />
            ))}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
              flexWrap="wrap"
              sx={{
                marginTop: "15px",
              }}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                onChange={recaptchaChange}
                onExpired={() => setCaptchaToken(false)}
                // theme="dark"
              />

              <Button
                onClick={sendMessage}
                sx={{
                  backgroundColor: "#253142",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#384A62",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <Box
            order={mobile ? 1 : 2}
            flex="2"
            sx={{
              width: "100%",
              minWidth: "300px",
              height: mobile ? "unset" : "70dvh",
            }}
          >
            <iframe
              src={mapLink}
              width="100%"
              height="100%"
              loading="lazy"
              title="Location"
              style={{ border: "none" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactForm;
