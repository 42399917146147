import { Backdrop } from "@mui/material";
import useMobile from "../hooks/useMobile";
import DesktopLayout from "../layout/DesktopLayout";
import MobileLayout from "../layout/MobileLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { createContext, useState } from "react";
import {
  IAboutUsData,
  IAboutUsDataContext,
  IBrochureContext,
  IBrochureType,
  IContactInfo,
  IContactInfoContext,
  ILoadingContext,
  IMapLinkContext,
  IOfficialInfo,
  IOfficialInfoContext,
  IOrganizationChartContext,
  IProjectsData,
  IProjectsDataContext,
  ISliderData,
  ISliderDataContext,
} from "../exports/interfaces";
import { useToast } from "../hooks/useToast";

export const LoadingContext = createContext<ILoadingContext>({
  setLoading: () => null,
});

export const SliderDataContext = createContext<ISliderDataContext>({
  sliderData: [],
  setSliderData: () => null,
});

export const ProjectsDataContext = createContext<IProjectsDataContext>({
  projectsData: [],
  setProjectsData: () => null,
});

export const AboutUsDataContext = createContext<IAboutUsDataContext>({
  aboutUsData: [],
  setAboutUsData: () => null,
});

export const BrochureContext = createContext<IBrochureContext>({
  brochure: [],
  setBrochure: () => null,
});

export const OrganizationChartContext =
  createContext<IOrganizationChartContext>({
    organizationChart: "",
    setOrganizationChart: () => null,
  });

export const OfficialInfoContext = createContext<IOfficialInfoContext>({
  officialInfo: [],
  setOfficialInfo: () => null,
});

export const ContactInfoContext = createContext<IContactInfoContext>({
  contactInfo: [],
  setContactInfo: () => null,
});

export const MapLinkContext = createContext<IMapLinkContext>({
  mapLink: "",
  setMapLink: () => null,
});

const CompanyProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const [sliderData, setSliderData] = useState<ISliderData[]>([]);
  const [projectsData, setProjectsData] = useState<IProjectsData[]>([]);
  const [aboutUsData, setAboutUsData] = useState<IAboutUsData[]>([]);
  const [officialInfo, setOfficialInfo] = useState<IOfficialInfo[]>([]);
  const [contactInfo, setContactInfo] = useState<IContactInfo[]>([]);
  const [brochure, setBrochure] = useState<IBrochureType[]>([]);
  const [organizationChart, setOrganizationChart] = useState<string>("");
  const [mapLink, setMapLink] = useState<string>("");

  return (
    <>
      <LoadingContext.Provider value={{ setLoading }}>
        <SliderDataContext.Provider value={{ sliderData, setSliderData }}>
          <ProjectsDataContext.Provider
            value={{ projectsData, setProjectsData }}
          >
            <AboutUsDataContext.Provider
              value={{ aboutUsData, setAboutUsData }}
            >
              <BrochureContext.Provider value={{ brochure, setBrochure }}>
                <OrganizationChartContext.Provider
                  value={{ organizationChart, setOrganizationChart }}
                >
                  <OfficialInfoContext.Provider
                    value={{ officialInfo, setOfficialInfo }}
                  >
                    <ContactInfoContext.Provider
                      value={{ contactInfo, setContactInfo }}
                    >
                      <MapLinkContext.Provider value={{ mapLink, setMapLink }}>
                        {useMobile() ? <MobileLayout /> : <DesktopLayout />}
                      </MapLinkContext.Provider>
                    </ContactInfoContext.Provider>
                  </OfficialInfoContext.Provider>
                </OrganizationChartContext.Provider>
              </BrochureContext.Provider>
            </AboutUsDataContext.Provider>
          </ProjectsDataContext.Provider>
        </SliderDataContext.Provider>
      </LoadingContext.Provider>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {useToast()}
    </>
  );
};

export default CompanyProfilePage;
