import { Box, Typography } from "@mui/material";
import { bgColor } from "../theme/theme";
import useMobile from "../hooks/useMobile";

const Footer = () => {
  return (
    <>
      <Box
        height={useMobile() ? "30px" : "20px"}
        minHeight={useMobile() ? "30px" : "20px"}
        bgcolor={bgColor}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx={useMobile() ? "-2px" : "0px"}
      >
        <Typography variant="h6">
          Copyright © 2024{" "}
          <a
            href="https://bestbnc.com.np/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              fontWeight: "700",
              color: "#384A62",
            }}
          >
            BB&C
          </a>{" "}
          | Powered by{" "}
          <a
            href="https://www.scieverinc.com/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              fontWeight: "700",
              color: "#384A62",
            }}
          >
            Sciever Inc.
          </a>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
