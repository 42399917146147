
const ErrorPage = () => {
  return (
    <>
      404 Page Not Found!
    </>
  )
}

export default ErrorPage
