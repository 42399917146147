import React, { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { PageContext } from "../App";

interface HamburgerProps {
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const Hamburger: React.FC<HamburgerProps> = ({
  showDetails,
  setShowDetails,
}) => {
  const { page } = useContext(PageContext);

  const iconStyle = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    color: showDetails
      ? "rgba(0,0,0,0.25)"
      : !showDetails && page !== "home"
      ? "rgba(0,0,0,0.25)"
      : "white",
    right: "30px",
    bottom: "30px",
    cursor: "pointer",
    zIndex: 10000,
    background:
      !showDetails && page !== "home" && page !== "contact"
        ? "linear-gradient(to right, #e3e3e3, #e3e3e3)"
        : "unset",
    borderRadius: "65px",
    padding: "5px",
    transition:
      "background-color 0.4s ease-in-out, background-position 0.4s ease-in-out",
    backgroundSize: "200% 100%",
    backgroundPosition: "left",
    "&:hover": {
      background:
        !showDetails && page !== "home" && page !== "contact"
          ? "linear-gradient(to right, #c2c2c2, #e3e3e3)"
          : "unset",
      color: showDetails
        ? "#9e9e9e"
        : !showDetails && page !== "home"
        ? "#9e9e9e"
        : "white",
      backgroundPosition: "right",
    },
  };

  return (
    <Box sx={iconStyle} onClick={() => setShowDetails((prevState) => !prevState)}>
      <MenuIcon sx={{ height: "50px", width: "50px" }} />
    </Box>
  );
};

export default Hamburger;
