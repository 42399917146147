import DescriptionIcon from "@mui/icons-material/Description";
import { IBrochureType } from "../exports/interfaces";

const Brochure = (props: { brochure: IBrochureType[] }) => {
  return (
    <>
      <a
        href={`${process.env.REACT_APP_strapiUrl}${props.brochure[0]?.attributes?.Brochure?.data?.attributes?.url}`}
        download="BB&C Brochure"
        target="_blank"
        rel="noreferrer"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          textDecoration: "none",
          color: "#384A62",
        }}
      >
        <DescriptionIcon />
        {props?.brochure[0]?.attributes?.Title}
      </a>
    </>
  );
};

export default Brochure;
