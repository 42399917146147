import ReactGA from "react-ga4";
import "./App.css";
import { createContext, useState } from "react";
import Main from "./routes/Main";

interface PageContextType {
  page: string;
  setPage: (page: string) => void;
}

export const PageContext = createContext<PageContextType>({
  page: "home",
  setPage: () => { },
});

const App = () => {
  ReactGA.initialize("G-CNWBZX85HG");
  const [page, setPage] = useState(window.location.pathname === '/' ? 'home' : window.location.pathname?.split('/')[1]);
  return (
    <>
      <PageContext.Provider value={{ page, setPage }}>
        <Main />
      </PageContext.Provider>
    </>
  );
};

export default App;
