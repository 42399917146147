import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Box } from "@mui/material";
import Info from "./Info";
import logo from "../assets/logo-png.png";
import useMobile from "../hooks/useMobile";
import { useContext, useEffect } from "react";
import axios from "axios";
import { headers } from "../exports/exports";
import { LoadingContext, SliderDataContext } from "../pages/CompanyProfilePage";
import { dynamicFailed } from "../hooks/useToast";
// import Details from "./Details";

const Slider = () => {
  const { setLoading } = useContext(LoadingContext);

  const { sliderData, setSliderData } = useContext(SliderDataContext);

  const mobile = useMobile();

  useEffect(() => {
    if (sliderData?.length === 0) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/api/sliders?sort=Order%3Aasc&populate=*`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setSliderData(res?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          dynamicFailed("Server Error");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const images = sliderData?.map((data) => {
    return {
      id: data?.id,
      heading: data?.attributes?.Title?.toUpperCase(),
      image: data?.attributes?.Image?.data?.attributes?.url,
      alignment: data?.attributes?.Image_Alignment?.toLowerCase(),
      content: {
        body: data?.attributes?.Description,
      },
    };
  });

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        style={{ height: "100%" }}
      >
        {images?.map((image) => {
          return (
            <SwiperSlide key={image.id}>
              <Box
                position="relative"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                p={mobile ? "15px 15px" : "35px"}
                boxSizing="border-box"
                sx={{
                  backgroundImage: `
                                        linear-gradient(
                                            to bottom,
                                            rgba(0, 0, 0, 0) 80%, 
                                            rgba(0, 0, 0, 1) 100%
      ),
      linear-gradient(
            to bottom right,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.8) 10%,
            rgba(255, 255, 255, 0.3) 20%,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0) 60%,
            rgba(255, 255, 255, 0) 70%,
            rgba(255, 255, 255, 0) 80%,
            rgba(255, 255, 255, 0) 90%,
            rgba(255, 255, 255, 0) 100%
            
          ),
  
                                        url(${process.env.REACT_APP_strapiUrl}${image?.image})
                                    `,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: `${image?.alignment}`,
                }}
              >
                <img src={logo} alt="logo" width="135px" />
                <Info content={image.content} heading={image.heading} />
              </Box>
            </SwiperSlide>
          );
        })}

        {/* {mobile && <Details />} */}
      </Swiper>

      <style>
        {`
                    .swiper-button-next,
                    .swiper-button-prev {
                        height: 26px;
                    }
                    .swiper-button-next::after,
                    .swiper-button-prev::after {
                        color: white;
                        font-size: 24px;
                    }
                    .swiper-pagination-bullet {
                        background-color: #ffffff;
                    }
                    .swiper-pagination {
                        bottom: 11px !important;
                    }
                    @media (max-width: 810px) {
                        .swiper-pagination {
                            bottom: 17px !important;
                        }
                    }
                `}
      </style>
    </>
  );
};

export default Slider;
