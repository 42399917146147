import { Box } from "@mui/material";

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
            style={{ height: '100%' }}
        >
            {value === index && (
                <Box height='100%'>
                    {children}
                </Box>
            )}
        </div>
    );
}
