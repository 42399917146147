import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { a11yProps, CustomTabPanel } from "./CustomTabs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMobile from "../hooks/useMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  LoadingContext,
  ProjectsDataContext,
} from "../pages/CompanyProfilePage";
import { headers } from "../exports/exports";
import axios from "axios";
import { dynamicFailed } from "../hooks/useToast";

const Projects = () => {
  const { setLoading } = useContext(LoadingContext);

  const { projectsData, setProjectsData } = useContext(ProjectsDataContext);

  const mobile = useMobile();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [completedExpanded, setCompletedExpanded] = useState<{
    [key: string]: number | false;
  }>({});
  const [ongoingExpanded, setOngoingExpanded] = useState<{
    [key: string]: number | false;
  }>({});

  const handleCompletedAccordionChange =
    (type: string, panel: number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCompletedExpanded(() => ({
        [`${type}-${panel}`]: isExpanded ? panel : false,
      }));
    };

  const handleOngoingAccordionChange =
    (type: string, panel: number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setOngoingExpanded(() => ({
        [`${type}-${panel}`]: isExpanded ? panel : false,
      }));
    };

  useEffect(() => {
    if (projectsData?.length === 0) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/api/projects?sort=createdAt%3Aasc&populate=*`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setProjectsData(res?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          dynamicFailed("Server Error");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface IProject {
    name: string;
    status: string;
    location: string;
    description: string;
    type: string;
    images: string[];
  }

  interface IGroupedData {
    [type: string]: {
      completed: IProject[];
      ongoing: IProject[];
    };
  }

  const groupedData = projectsData?.reduce((acc: IGroupedData, currentItem) => {
    const type = currentItem.attributes.Type;
    const status = currentItem.attributes.Status;
    if (!acc[type]) {
      acc[type] = { completed: [], ongoing: [] };
    }
    const images =
      currentItem.attributes.Images?.data?.map((img) => img.attributes.url) ||
      [];

    const projectData = {
      name: currentItem.attributes.Name,
      description: currentItem.attributes.Description,
      status: currentItem.attributes.Status,
      location: currentItem.attributes.Location,
      type: currentItem.attributes.Type,
      images: images,
    };

    if (status === "Completed") {
      acc[type].completed.push(projectData);
    } else if (status === "Ongoing") {
      acc[type].ongoing.push(projectData);
    }

    return acc;
  }, {});

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
            },
          }}
        >
          <Tab
            label="Completed"
            {...a11yProps(0)}
            sx={{ "&.Mui-selected": { color: "black" } }}
          />
          <Tab
            label="Ongoing"
            {...a11yProps(1)}
            sx={{ "&.Mui-selected": { color: "black" } }}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={tabValue} index={0}>
        <Box
          height="calc(100% - 50px)"
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {Object.keys(groupedData).map((type) => {
            const completedProjects = groupedData[type].completed;
            if (completedProjects.length > 0) {
              return (
                <div key={type}>
                  <h2 style={{ fontWeight: "500", padding: "0px 10px" }}>
                    {type}
                  </h2>
                  {completedProjects.map((item, index: number) => (
                    <Accordion
                      key={index}
                      expanded={completedExpanded[`${type}-${index}`] === index}
                      onChange={handleCompletedAccordionChange(type, index)}
                      disableGutters
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ height: "50px" }}
                      >
                        {!mobile ? (
                          <Typography color="black" fontSize="16px">
                            {item.name}
                          </Typography>
                        ) : (
                          <Typography
                            color="black"
                            fontSize="14px !important"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            width="280px"
                          >
                            {item.name}
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          display: "flex",
                          flexDirection: mobile ? "column" : "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          order={mobile ? 2 : 1}
                          sx={{
                            flex: 1,
                            marginRight: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            paddingLeft: !mobile ? "8px" : "unset",
                          }}
                        >
                          {item.description && (
                            <Typography
                              color="black"
                              fontSize="12px !important"
                              lineHeight="25px"
                            >
                              {item.description}
                            </Typography>
                          )}
                          <Typography
                            color="black"
                            fontSize="12px !important"
                            lineHeight="25px"
                          >
                            Location: {item.location}
                          </Typography>
                          <Typography
                            color="black"
                            fontSize="12px !important"
                            lineHeight="25px"
                          >
                            Type: {item.type}
                          </Typography>
                          <Typography
                            color="black"
                            fontSize="12px !important"
                            lineHeight="25px"
                          >
                            Status: {item.status}
                          </Typography>
                        </Box>
                        <Box
                          order={mobile ? 1 : 2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover .swiper-button-next, &:hover .swiper-button-prev":
                              {
                                opacity: 1,
                                display: "flex",
                              },
                          }}
                        >
                          <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            slidesPerView={3}
                            navigation
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 3000 }}
                            style={{
                              height: "100%",
                              width: mobile ? "322px" : "470px",
                            }}
                          >
                            {item.images?.map((image, index: number) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={`${process.env.REACT_APP_strapiUrl}${image}`}
                                  alt=""
                                  style={{
                                    width: mobile ? "100px" : "150px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              );
            }
            return null;
          })}
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <Box
          height="calc(100% - 50px)"
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {Object.keys(groupedData).map((type) => {
            const ongoingProjects = groupedData[type].ongoing;
            if (ongoingProjects.length > 0) {
              return (
                <div key={type}>
                  <h2 style={{ fontWeight: "500", padding: "0px 10px" }}>
                    {type}
                  </h2>
                  {ongoingProjects.map((item, index: number) => (
                    <Accordion
                      key={index}
                      expanded={ongoingExpanded[`${type}-${index}`] === index}
                      onChange={handleOngoingAccordionChange(type, index)}
                      disableGutters
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ height: "50px" }}
                      >
                        {!mobile ? (
                          <Typography color="black" fontSize="16px">
                            {item.name}
                          </Typography>
                        ) : (
                          <Typography
                            color="black"
                            fontSize="14px !important"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            width="280px"
                          >
                            {item.name}
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          display: "flex",
                          flexDirection: mobile ? "column" : "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          order={mobile ? 2 : 1}
                          sx={{
                            flex: 1,
                            marginRight: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            paddingLeft: !mobile ? "8px" : "unset",
                          }}
                        >
                          {item.description && (
                            <Typography
                              color="black"
                              fontSize="12px !important"
                              lineHeight="25px"
                            >
                              {item.description}
                            </Typography>
                          )}
                          <Typography
                            color="black"
                            fontSize="12px !important"
                            lineHeight="25px"
                          >
                            Location: {item.location}
                          </Typography>
                          <Typography
                            color="black"
                            fontSize="12px !important"
                            lineHeight="25px"
                          >
                            Type: {item.type}
                          </Typography>
                          <Typography
                            color="black"
                            fontSize="12px !important"
                            lineHeight="25px"
                          >
                            Status: {item.status}
                          </Typography>
                        </Box>
                        <Box
                          order={mobile ? 1 : 2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover .swiper-button-next, &:hover .swiper-button-prev":
                              {
                                opacity: 1,
                                display: "flex",
                              },
                          }}
                        >
                          <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            slidesPerView={3}
                            navigation
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 3000 }}
                            style={{
                              height: "100%",
                              width: mobile ? "322px" : "470px",
                            }}
                          >
                            {item.images?.map((image, index: number) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={`${process.env.REACT_APP_strapiUrl}${image}`}
                                  alt=""
                                  style={{
                                    width: mobile ? "100px" : "150px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              );
            }
            return null;
          })}
        </Box>
      </CustomTabPanel>

      <style>
        {`
                    .swiper-button-next,
                    .swiper-button-prev {
                        opacity: ${mobile ? "1" : "0"};
                        display: ${mobile ? "flex" : "none"};
                        height: 22px;
                        position: absolute;
                        top: 86px;
                    }
                    .swiper-button-next::after,
                    .swiper-button-prev::after {
                        color: white;
                        font-size: 24px;
                    }
                    .swiper-button-prev {
                        left: 0px;
                    }

                    .swiper-button-next {
                        right: 5px; 
                    }
                    .swiper-pagination-bullet {
                        display: none;
                    }
                    .swiper-pagination {
                        bottom: 11px !important;
                    }
                    @media (max-width: 810px) {
                        .swiper-pagination {
                            bottom: 17px !important;
                        }
                    }
                `}
      </style>
    </>
  );
};

export default Projects;
