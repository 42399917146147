import { createTheme } from "@mui/material/styles";

export const bgColor = "#FFFFFF";

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Inter",
      fontWeight: "300",
      fontSize: "45px",
      color: "white",
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      "@media (min-width:810px)": {
        fontSize: "50px",
      },
    },
    button: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "14px",
    },
    body1: {
      fontFamily: "Roboto",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "50px",
      color: "white",
      opacity: 0.75,
      "@media (max-width:810px)": {
        lineHeight: "30px",
        fontSize: "16px",
      },
    },
    body2: {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "14px",
      color: "white",
    },
    h6: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "12px",
      color: "#384A62",
    },
    h5: {
      fontFamily: "Inter",
      fontWeight: "700",
      fontSize: "14px",
      color: "rgba(56, 74, 98, 1)",
    },
    h4: {
      fontFamily: "Inter",
      fontWeight: "700",
      fontSize: "13px",
      color: "rgba(56, 74, 98, 1)",
      "@media (min-width:810px)": {
        fontSize: "12px",
      },
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "12px",
      color: "rgba(56, 74, 98, 1)",
    },
    caption: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "13px",
      letterSpacing: "0px",
      color: "rgba(56, 74, 98, 1)",
      lineHeight: "13.5px",
      "@media (min-width:810px)": {
        fontSize: "12px",
      },
    },
  },
});

export default theme;
