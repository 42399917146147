import { BrowserRouter, Route, Routes } from "react-router-dom";
import CompanyProfilePage from "../pages/CompanyProfilePage";
import ErrorPage from "../pages/ErrorPage";
import SuyogVC from "../components/SuyogVC";
import SajinVC from "../components/SajinVC";

const Main = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CompanyProfilePage />} />
          <Route path="/projects" element={<CompanyProfilePage />} />
          <Route path="/aboutus" element={<CompanyProfilePage />} />
          <Route path="/contact" element={<CompanyProfilePage />} />
          <Route path="/suyog" element={<SuyogVC />} />
          <Route path="/sajin" element={<SajinVC />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Main;
