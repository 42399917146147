import { Box, Slide } from "@mui/material";
import { bgColor } from "../theme/theme";
import Details from "../components/Details";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import { useContext, useRef, useState } from "react";
import AboutUs from "../components/AboutUs";
import Projects from "../components/Projects";
import Hamburger from "../components/Hamburger";
import ContactForm from "../components/ContactForm";
import { PageContext } from "../App";

const DesktopLayout = () => {

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const { page } = useContext(PageContext);

  const containerRef = useRef(null);

  return (
    <>
      <Box
        padding="25px"
        width="100dvw"
        height="100dvh"
        bgcolor={bgColor}
        boxSizing="border-box"
      >
        <Box
          width="100%"
          height="100%"
          border="1px solid black"
          borderColor="rgba(0,0,0,0.1)"
          display="flex"
          ref={containerRef}
          overflow="hidden"
        >
          <Box flex="1" overflow="hidden" padding={page === "projects" ? "0px 15px" : "unset"}>
            {page === "home" ? (
              <>
                <Slider />
                <Hamburger
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              </>
            ) : page === "aboutus" ? (
              <>
                <AboutUs />
                <Hamburger
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              </>
            ) : page === "projects" ? (
              <>
                <Projects />
                <Hamburger
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              </>
            ) : page === "contact" ? (
              <>
                <ContactForm />
                <Hamburger
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              </>
            ) : null}
          </Box>

          <Slide
            direction="left"
            in={showDetails}
            mountOnEnter
            unmountOnExit
            container={containerRef.current}
          >
            <Box
              width="285px"
              borderLeft="1px solid black"
              borderColor="rgba(0,0,0,0.1)"
            >
              <Details />
            </Box>
          </Slide>
        </Box>
      </Box>

      <Box position="absolute" bottom="1px" right="25px">
        <Footer />
      </Box>
    </>
  );
};

export default DesktopLayout;
