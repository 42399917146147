import { Box, Link, Typography } from "@mui/material";
import useMobile from "../hooks/useMobile";
import { useState } from "react";
import useTablet from "../hooks/useTablet";

const Info = (props: {
  content: {
    body: string;
  };
  heading: string;
}) => {
  const mobile = useMobile();
  const tablet = useTablet();

  const [expanded, setExpanded] = useState(false);
  const [fadeEffect, setFadeEffect] = useState(false);

  const toggleExpand = () => {
    setFadeEffect(true); 
    setTimeout(() => {
      setExpanded((prev) => !prev);
      setFadeEffect(false); 
    }, 300); 
  };

  const charLimit = mobile ? 60 : tablet ? 150 : 210;

  const isTruncated = props?.content?.body?.length > charLimit;

  const truncatedText = isTruncated
    ? props?.content?.body?.slice(0, charLimit) + " - "
    : props?.content?.body;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width={mobile ? "100%" : "85%"}
        alignItems="none"
        mb={mobile ? "25px" : "0px"}
      >
        <Typography
          display="flex"
          justifyContent="center"
          variant="h1"
          sx={{
            alignSelf: "flex-start",
            fontWeight: "1000",
            opacity: 0.75,
            letterSpacing: -2,
          }}
        >
          {props.heading}
        </Typography>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          mb={mobile ? "5px" : "0px"}
        >
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: expanded ? "none" : 2,
              overflow: "hidden",
              opacity: fadeEffect ? 0 : 1, 
              transition: "opacity 0.5s ease-in-out", 
            }}
          >
            {expanded ? props?.content?.body : truncatedText}
            {!expanded && isTruncated && (
              <Link
                component="button"
                onClick={toggleExpand}
                sx={{
                  ml: 1,
                  mt: "-1.5px",
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bold",
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    width: "0%",
                    height: "2px",
                    backgroundColor: "white",
                    transition: "width 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    width: !mobile ? "100%" : "unset",
                  },
                }}
              >
                More...
              </Link>
            )}
            {expanded && isTruncated && (
              <Link
                component="button"
                onClick={toggleExpand}
                sx={{
                  ml: 1,
                  mt: "-1.5px",
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bold",
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    width: "0%",
                    height: "2px",
                    backgroundColor: "white",
                    transition: "width 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    width: !mobile ? "100%" : "unset",
                  },
                }}
              >
                Less...
              </Link>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Info;
