import React, { useState, useEffect, useContext } from "react";
import { Box, Link, Dialog, DialogContent, Typography } from "@mui/material";
// import chart from "../assets/desktop/organizationChart.png";
import Brochure from "./Brochure";
import useMobile from "../hooks/useMobile";
import { headers } from "../exports/exports";
import axios from "axios";
import { marked } from "marked";
import {
  AboutUsDataContext,
  BrochureContext,
  LoadingContext,
  OrganizationChartContext,
} from "../pages/CompanyProfilePage";
import { dynamicFailed } from "../hooks/useToast";
import { ReactPanZoom } from "./ReactPanZoom";

const AboutUs = () => {
  const { setLoading } = useContext(LoadingContext);

  const { aboutUsData, setAboutUsData } = useContext(AboutUsDataContext);

  const { brochure, setBrochure } = useContext(BrochureContext);

  const { organizationChart, setOrganizationChart } = useContext(
    
    OrganizationChartContext
  
  );

  const mobile = useMobile();

  const [open, setOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [fadeEffects, setFadeEffects] = useState<boolean[]>([]);

  useEffect(() => {
    if (
      aboutUsData?.length === 0 ||
      brochure?.length === 0 ||
      organizationChart === ""
    ) {
      setLoading(true);

      const getOrganizationChart = () => {
        axios
          .get(
            `${process.env.REACT_APP_strapiUrl}/api/organization-charts?pagination[pageSize]=1&sort=createdAt%3Adesc&populate=*`,
            {
              headers: headers,
            }
          )
          .then((res) => {
            setOrganizationChart(
              res?.data?.data[0]?.attributes?.Organization_Chart?.data
                ?.attributes?.url
            );
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            dynamicFailed("Server Error");
          });
      };

      const getBrochure = () => {
        axios
          .get(
            `${process.env.REACT_APP_strapiUrl}/api/brochures?pagination[pageSize]=1&sort=createdAt%3Adesc&populate=*`,
            {
              headers: headers,
            }
          )
          .then((res) => {
            setBrochure(res?.data?.data);
            getOrganizationChart();
          })
          .catch(() => {
            setLoading(false);
            dynamicFailed("Server Error");
          });
      };

      axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/api/about-uses?sort=Order%3Aasc`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setAboutUsData(res?.data?.data);
          getBrochure();
        })
        .catch(() => {
          setLoading(false);
          dynamicFailed("Server Error");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const charLimit = mobile ? 560 : 820;

  const handleToggle = (index: number) => {
    const newFadeEffects = [...fadeEffects];
    newFadeEffects[index] = true;
    setFadeEffects(newFadeEffects);

    setTimeout(() => {
      setExpandedIndex(expandedIndex === index ? null : index);
      newFadeEffects[index] = false;
      setFadeEffects(newFadeEffects);
    }, 300);
  };

  const handleClickOpen = () => {
    if (mobile) {
      setOpen(true);
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <Box
        px={mobile ? "5px" : "25px"}
        position="relative"
        height="100%"
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          textAlign: "justify",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {brochure && (
          <Box position="absolute" top="25px" right="25px">
            <Brochure brochure={brochure} />
          </Box>
        )}

        {aboutUsData?.map((item, index) => {
          const markedDescription = marked(
            item?.attributes?.Description || ""
          ) as string;

          const truncatedDescription =
            markedDescription.length > charLimit
              ? markedDescription.slice(0, charLimit) + "..."
              : markedDescription;

          const isExpanded = expandedIndex === index;

          return (
            <div key={index}>
              <h2 style={{ fontWeight: "500" }}>{item?.attributes?.Title}</h2>
              <div
                style={{
                  opacity: fadeEffects[index] ? 0 : 1,
                  transition: "opacity 0.3s ease-in-out",
                }}
                dangerouslySetInnerHTML={{
                  __html: isExpanded ? markedDescription : truncatedDescription,
                }}
              />
              {markedDescription.length > charLimit && (
                <Link
                  component="button"
                  onClick={() => handleToggle(index)}
                  sx={{
                    mt: "-25px",
                    color: "black",
                    textDecoration: "none",
                    fontWeight: "bold",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: 0,
                      width: "0%",
                      height: "2px",
                      backgroundColor: "black",
                      transition: "width 0.3s ease-in-out",
                    },
                    "&:hover::after": {
                      width: !mobile ? "100%" : "unset",
                    },
                  }}
                >
                  {isExpanded ? "Less..." : "More..."}
                </Link>
              )}
            </div>
          );
        })}

        {organizationChart && (
          <>
            <h2 style={{ fontWeight: "500" }}>Organization Chart</h2>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              // marginTop="-15px"
              position="relative"
              overflow="hidden"
              border="1px solid black"
              borderColor="rgba(0,0,0,0.1)"
              marginBottom= {mobile ? "unset" : "0.83em"}
            >
              {mobile ? (
                <img
                  src={`${process.env.REACT_APP_strapiUrl}${organizationChart}`}
                  alt="Organization Chart"
                  width="100%"
                  onClick={handleClickOpen}
                  style={{ cursor: mobile ? "pointer" : "default" }}
                />
              ) : (
                <ReactPanZoom
                  image={`${process.env.REACT_APP_strapiUrl}${organizationChart}`}
                  alt="Organization Chart"
                  setOpen={setOpen}
                />
              )} 
            </Box>
            {mobile && (
              <Box display="flex" justifyContent="center">
                <Typography color="black" fontSize='10px !important'>Tap To View</Typography>
              </Box>
            )}
          </>
        )}
        {mobile && (
          <Dialog
            open={open}
            maxWidth="lg"
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <DialogContent
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {/* <img
                src={chart}
                alt="Organization Chart"
                style={{
                  maxWidth: "90dvh",
                  maxHeight: "90dvw",
                  objectFit: "contain",
                  transform: "rotate(90deg)",
                  transformOrigin: "center",
                }}
                onClick={handleClose}
              /> */}
              <ReactPanZoom
                image={`${process.env.REACT_APP_strapiUrl}${organizationChart}`}
                alt="Organization Chart"
                setOpen={setOpen}
              />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </>
  );
};

export default AboutUs;
