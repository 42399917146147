import { Box, Drawer } from "@mui/material";
import { useContext, useState } from "react";
import useMobile from "../hooks/useMobile";
import AllDetails from "./AllDetails";
import MenuIcon from "@mui/icons-material/Menu";
import { PageContext } from "../App";

const Details = () => {
  const mobile = useMobile();

  const [open, setOpen] = useState(false);
  const { page } = useContext(PageContext);

  return (
    <>
      {mobile ? (
        <>
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              color: page === "home" ? "white" : "rgba(0,0,0,0.25)",
              right: "15px",
              bottom: "30px",
              cursor: "pointer",
              zIndex: 1,
              background: (page !== 'home' && page !== 'contact') ? "#e3e3e3" : 'unset',
              borderRadius: '45px',
              padding: '5px'
            }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon sx={{ width: "45px", height: "45px" }} />
          </Box>

          <Drawer
            anchor="bottom"
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
              style: {
                height: "570px",
                padding: "8px 8px 0px 8px",
              },
            }}
          >
            <AllDetails setOpenDrawer={setOpen} />
          </Drawer>
        </>
      ) : (
        <AllDetails />
      )}
    </>
  );
};

export default Details;
