import { Box } from "@mui/material";
import { bgColor } from "../theme/theme";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import { useContext } from "react";
import { PageContext } from "../App";
import AboutUs from "../components/AboutUs";
import Projects from "../components/Projects";
import ContactForm from "../components/ContactForm";
import Details from "../components/Details";

const MobileLayout = () => {
  const { page } = useContext(PageContext);
  return (
    <>
      <Box
        width="100dvw"
        height="100dvh"
        bgcolor={bgColor}
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <Box flex="1" boxSizing="border-box" p="7px 7px 0px 7px" overflow="hidden">
          {page === "home" ? (
            <>
              <Slider />
            </>
          ) : page === "aboutus" ? (
            <>
              <AboutUs />
            </>
          ) : page === "projects" ? (
            <>
              <Projects />
            </>
          ) : page === "contact" ? (
            <>
              <ContactForm />
            </>
          ) : null}

          <Details />
        </Box>

        <Footer />
      </Box>
    </>
  );
};

export default MobileLayout;
