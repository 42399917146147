import "../assets/css/vc-styles.css";
import qr from "../assets/suyog-qr.png";
import logo from "../assets/logo-png.png";

const SuyogVC = () => {
  return (
    <>
      <div className="main">
        <div className="container">
          <div className="images">
            <div className="log">
              <img
                src={logo}
                alt="Logo"
                width="100%"
                height="auto"
                onClick={() =>
                  window.open("https://www.bbnc.com.np/", "_blank")
                }
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="qr">
              <img src={qr} alt="QR" width="100%" height="auto" />
            </div>
          </div>
          <div className="info">
            <div className="location">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/A3dj2WUWVLNwfVGS9"
                style={{ textDecoration: "none", color: "#324964" }}
              >
                Seto Durbar, Jamal, Kathmandu, Nepal
              </a>
            </div>
            <div className="detail">
              <div className="name">
                <span className="identity">Suyog Krishna Shrestha</span>
                <span className="position">Managing Director</span>
              </div>
              <div className="e-address">
                <div className="number">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel: +977-9841333173"
                    className="n1"
                  >
                    +977-9841333173
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel: +977-9802499345"
                    className="n1"
                  >
                    +977-9802499345
                  </a>
                </div>
                <div className="line"></div>
                <div className="email">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto: suyogkrishnashrestha@gmail.com"
                    className="e1"
                  >
                    suyogkrishnashrestha@gmail.com
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.bbnc.com.np/"
                    className="e2"
                  >
                    www.bbnc.com.np
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuyogVC;
