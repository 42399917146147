import { Box, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { WidgetsRounded, Info, HomeRounded } from "@mui/icons-material";
import useMobile from "../hooks/useMobile";
import { useContext } from "react";
import { PageContext } from "../App";
import { useNavigate } from "react-router-dom";
import { OfficialInfoContext } from "../pages/CompanyProfilePage";

const MainDetail = ({ data, setOpenDrawer }: any) => {
  const { page, setPage } = useContext(PageContext);

  const mobile = useMobile();

  const navigate = useNavigate();

  const { officialInfo } = useContext(OfficialInfoContext);

  const renderIcon = (index: number) => {
    if (index % 3 === 0) {
      return <HomeRounded className="menuIcon" sx={iconStyle} />;
    } else if (index % 3 === 1) {
      return <WidgetsRounded className="menuIcon" sx={iconStyle} />;
    } else {
      return <Info className="menuIcon" sx={iconStyle} />;
    }
  };

  const iconStyle = {
    ml: 5,
    color: "#e5e5e5",
    fontSize: 85,
    position: "absolute",
    bottom: -20,
    right: -20,
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid lightgray"
        borderTop={mobile ? "1px solid black" : "none"}
        borderColor="rgba(0,0,0,0.1)"
        px={mobile ? "20px" : "10px"}
        height="35px"
      >
        <Box display="flex" alignItems="center" gap="5px">
          <CopyrightIcon
            sx={{
              width: "14px",
              height: "14px",
              color: "rgba(56, 74, 98, 1)",
              mt: mobile ? "1px" : "-1px",
            }}
          />
          <Typography variant="h4">For Official Use Only</Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        py="10px"
        flexDirection="column"
        alignItems="left"
        borderBottom="1px solid lightgray"
        borderColor="rgba(0,0,0,0.1)"
        px={mobile ? "20px" : "10px"}
        gap="5px"
      >
        {officialInfo?.map((info) => {
          return (
            <Box display="flex" flexDirection="row" gap="5px" key={info?.id}>
              <Typography variant="h4">{info?.attributes?.Title}:</Typography>

              <Typography variant="h4" fontWeight="400">
                {info?.attributes?.Info}
              </Typography>
            </Box>
          );
        })}
      </Box>

      {data?.map((item: any, idx: any) => (
        <Box
          key={idx}
          display="flex"
          position="relative"
          flex="1"
          p={mobile ? "10px 20px" : "10px"}
          overflow="hidden"
          flexDirection="column"
          gap="5px"
          borderBottom="1px solid lightgray"
          borderColor="rgba(0,0,0,0.1)"
          onClick={() => {
            setPage(item.type);
            navigate(item.type === "home" ? "/" : `/${item.type}`);
            mobile && setOpenDrawer(false);
          }}
          sx={{
            cursor: page !== item?.type ? "pointer" : "default",
            position: "relative",
            "&:hover .contact-text::after": {
              width: !mobile
                ? item.title === "HOME"
                  ? "72px"
                  : item.title === "PROJECTS"
                  ? "127px"
                  : item.title === "ABOUT US"
                  ? "122px"
                  : "unset"
                : "unset",
            },
          }}
        >
          <Typography
            className="contact-text"
            color="black"
            fontSize="25px"
            lineHeight="25px"
            sx={{
              textDecoration: page === item?.type ? "underline" : "unset",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "0%",
                height: "2px",
                backgroundColor: "black",
                transition: "width 0.3s ease-in-out",
              },
              // boxShadow: !mobile
              //   ? `inset ${
              //       page === "home" && page === item?.type
              //         ? "72px"
              //         : page === "projects" && page === item?.type
              //         ? "127px"
              //         : page === "aboutus" && page === item?.type
              //         ? "122px"
              //         : "unset"
              //     } 0 0 0 black`
              //   : "unset",
            }}
          >
            {item.title}
          </Typography>
          {renderIcon(idx)}
        </Box>
      ))}
    </>
  );
};

export default MainDetail;
